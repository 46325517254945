import React from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import {Container} from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import logo from './../../assets/images/amaranthos-logo.png';
import logoClean from './../../assets/images/amaranthos-logo-clean.png';

import './menu.scss';


class Menu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            position: 0
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        this.setState({position: window.scrollY})
    };

    render() {

        const scrolled = this.state.position > 10 ? 'scrolled' : '';

        return(
            <Navbar expand="lg" fixed="top" className={scrolled}>
                <Container>
                    <Navbar.Brand>
                        <img src={(this.state.position ? logoClean : logo)}
                             className={scrolled}
                             alt="Amaranthos" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <AnchorLink className="nav-link" href="#home">Home</AnchorLink>
                            <AnchorLink className="nav-link" href="#over-ons">Het bedrijf</AnchorLink>
                            <AnchorLink className="nav-link" href="#assortiment">Assortiment</AnchorLink>
                            <AnchorLink className="nav-link" href="#verzorging">Verzorging</AnchorLink>
                            <AnchorLink className="nav-link" href="#footer">Contact</AnchorLink>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default Menu;
