import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import fbLogo from './../../assets/images/fb-logo-small.png';
import instaLogo from './../../assets/images/insta-logo-small.png';

import './footer.component.scss';


class Footer extends React.Component {

    render() {
        return (
            <section className="footer" id="footer">
                <Container>
                    <Row>
                        <Col className="cell">
                            <label>LOCATIE WESTLAND</label>
                            De Jonghlaan 6<br />
                            2691 NM ’s-Gravenzande<br />
                            T: Martin Vellekoop <a href="tel:+316-51405663">06 - 5140 5663</a><br />
                            E: <a href="mailto:verkoopwestland@amaranthosflowers.nl">verkoopwestland@amaranthosflowers.nl</a>
                        </Col>
                        <Col className="cell">
                            <label>LOCATIE BLEISWIJK</label>
                            Groendalseweg 82<br />
                            2665 MG Bleiswijk<br />
                            T: Edwin Vellekoop <a href="tel:+316-21877226">06 - 2187 7226</a><br />
                            E: <a href="mailto:verkoopbleiswijk@amaranthosflowers.nl">verkoopbleiswijk@amaranthosflowers.nl</a>
                        </Col>
                        <Col className="cell">

                        </Col>
                        {/*<Col className="cell" sm={12} md={3}>*/}

                        {/*</Col>*/}
                    </Row>
                    <Row className="footer-bottom">
                        <Col sm={12} md={6} className="left-align">
                            &copy; Copyright 2019 Amaranthos
                        </Col>
                        <Col sm={12} md={6} className="right-align">
                            <a href="https://www.facebook.com/profile.php?id=100015423107724"
                               rel="noopener noreferrer"
                               target="_blank">
                                <img src={fbLogo} height={30} />
                            </a>
                            <div className="seperator">|</div>
                            <a href="https://www.instagram.com/amaranthosflowers/"
                               rel="noopener noreferrer"
                               target="_blank">
                                <img src={instaLogo} height={30} />
                            </a>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Footer;
