import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {Carousel, CarouselItem} from "react-bootstrap";

import banner4 from './../../assets/images/slides/banner-004.jpg';
import banner5 from './../../assets/images/slides/banner-005.jpg';
import banner6 from './../../assets/images/slides/banner-006.jpg';

import droneVideo from './../../assets/video/amaranthos-drone.mp4';
import timelaps from './../../assets/video/amaryllis-timelaps.mp4';
import data from './../../assets/data/products';

import Footer from "../../components/footer/footer.component";
import Popup from "../../components/popup/popup.component";

const images = require.context('../../assets/images/products', true);


class Homepage extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            carouselIndex: 0,
            showBox: 0,
        };
    }

    onSelect = (e) => {
        this.setState({carouselIndex: e});
    };


    render() {

        return(
            <React.Fragment>

                <section className="section-row first-row" id="home">
                    <Row>
                        <Col xs={12} md={{span: 10, offset: 1}}>

                            <div className="carousel-index d-none d-sm-block">
                                <div className={`carousel-index-item ${this.state.carouselIndex === 0 ? 'active' : ''}`}
                                     onClick={() => this.setState({carouselIndex: 0})}>
                                    01 <span className="line"></span></div>
                                <div className={`carousel-index-item ${this.state.carouselIndex === 1 ? 'active' : ''}`}
                                     onClick={() => this.setState({carouselIndex: 1})}>
                                    02 <span className="line"></span></div>
                                <div className={`carousel-index-item ${this.state.carouselIndex === 2 ? 'active' : ''}`}
                                     onClick={() => this.setState({carouselIndex: 2})}>
                                    03 <span className="line"></span></div>
                            </div>

                            <Carousel fade={true}
                                      indicators={false}
                                      activeIndex={this.state.carouselIndex}
                                      onSelect={this.onSelect}>
                                <CarouselItem>
                                    <img src={banner4} className="d-block w-100" alt="Second slide" />
                                </CarouselItem>
                                <CarouselItem>
                                    <img src={banner5} className="d-block w-100" alt="Second slide" />
                                </CarouselItem>
                                <CarouselItem>
                                    <img src={banner6} className="d-block w-100" alt="Second slide" />
                                </CarouselItem>
                            </Carousel>
                        </Col>
                    </Row>
                </section>

                <section className="bg-yellow-light section-row" id="over-ons">
                    <Container>
                        <Row className="xtra-pad">
                            <Col sm={12} md={6}>
                                <h2 className="section-title">Amaranthos Flowers</h2>
                                <p className="promo-text">
                                    Op twee locaties van bij elkaar ruim zes hectare teelt Amaranthos Flowers een breed en volwaardig assortiment amaryllissen. Met meer dan dertig jaar teeltervaring is de kwaliteit van onze prachtige amaryllissen hoog te noemen.<br />
                                    <br />
                                    Amaranthos Flowers teelt met zorg en oog voor duurzaamheid. Er wordt gebruik gemaakt van restwarmte, een warmtepomp en bronnen voor warmte en koude opslag. Ook zijn beide locaties MPS A en GLOBAL-GAP gekwalificeerd.<br />
                                    <br />
                                    Met dagelijkse aandacht en passie werken wij met ons team aan topkwaliteit. Zo realiseren wij een eindproduct waar we trots op zijn. Via onze afnemers kan de consument uiteindelijk genieten van onze mooie bloemen met een lange levensduur. De Amaranthos Amaryllis, simply beautiful.
                                </p>
                            </Col>
                            <Col sm={12} md={6} className="vert-center">

                                <div className="video-container">
                                    <video className="video"
                                           src={droneVideo}
                                           loop={true}
                                           controls={false}
                                           autoPlay={true}>
                                        Uw browser ondersteunt het element <code>video</code> niet.
                                    </video>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section-row" id="assortiment">
                    <Container>
                        <Row className="title-row">
                            <Col xs={{span: 10, offset: 1}} className="title">
                                "De amaryllis komt oorspronkelijk uit Midden- en Zuid-Amerika en staat voor trots, bewondering en betoverende schoonheid."
                            </Col>
                        </Row>
                        <Row>

                            {data.products.map((product, index) => {
                                const image = (product.imageUrl ? images(product.imageUrl) : false);
                                return (
                                <Col xs={12} md={6} lg={3} key={index}>
                                    <div className="box">
                                        <div className="inner" onClick={() => this.setState({showBox: product.id})}>
                                            <div className="product--title">{product.title}</div>
                                            <div className="product--image" style={{backgroundImage: `url('${image}')`}} />
                                        </div>
                                    </div>
                                    <Popup size="lg" productTitle={product.title} options={product.options} show={this.state.showBox === product.id} onHide={() => this.setState({showBox: 0})} />
                                </Col>
                                )
                            })}

                        </Row>
                    </Container>
                </section>

                <section className="bg-yellow-light section-row" id="verzorging">
                    <Container>
                        <Row className="xtra-pad">
                            <Col sm={12} md={12}>
                                <h2 className="section-title">Verzorging</h2>
                            </Col>
                            <Col sm={12} md={6}>
                                <table className="promo-text">
                                    <tbody>
                                        <tr className="pad-10">
                                            <td>1)</td>
                                            <td>Gebruik bij voorkeur een stevige vaas met een smalle hals zodat de stelen iets dichter op elkaar blijven staan.</td>
                                        </tr>
                                        <tr className="pad-10">
                                            <td>2)</td>
                                            <td>Snij de stelen schuin af, dit kan je na een paar dagen opnieuw doen om de houdbaarheid te bevorderen.</td>
                                        </tr>
                                        <tr className="pad-10">
                                            <td>3)</td>
                                            <td>Plak eventueel een stukje tape aan de onderkant van de stelen, dit voorkomt omkrullen.</td>
                                        </tr>
                                        <tr className="pad-10">
                                            <td>4)</td>
                                            <td>Gebruik niet teveel water en ververs het tijdig.</td>
                                        </tr>
                                        <tr className="pad-10">
                                            <td>5)</td>
                                            <td>Amarylissen hebben geen snijbloemenvoedsel nodig, maar de bloem kan er wel tegen (als de amaryllis is verwerkt in een gemengd boeket bijvoorbeeld).</td>
                                        </tr>
                                        <tr className="pad-10">
                                            <td>6)</td>
                                            <td>De bloem groeit naar het licht toe, draai de vaas om het boeket zo mooi mogelijk te houden.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                            <Col sm={12} md={6} className="vert-center">

                                <div className="video-container">
                                    <video className="video"
                                           src={timelaps}
                                           loop={true}
                                           controls={false}
                                           muted={true}
                                           autoPlay={true}>
                                        Uw browser ondersteunt het element <code>video</code> niet.
                                    </video>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </section>

                <Footer></Footer>

            </React.Fragment>
        );
    }
}


export default Homepage;
