import React from 'react';
import Modal from 'react-bootstrap/Modal';

import {Col, Container, Row} from 'react-bootstrap';

const images = require.context('../../assets/images/products', true);

const Popup = (props) => {

    const [show, setShow] = React.useState(false);

    const {size, onHide} = props;

    React.useEffect(() => {
        setShow(props.show);
    }, [props]);


    return(
        <Modal onHide={onHide} size={size} show={show} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton />
            <Modal.Body>

                <Container>

                    {props.productTitle === "Specials" &&
                    <Row className="option-row">
                        <Col xs={12} style={{padding: "20px 0"}}>
                            <h5 className="option-name text-center">Specials</h5>
                            <span className="option-descr text-center">
                                Op verzoek kunnen we de bloemen ook in andere kleuren krijgen door een speciale kleurbehandeling. Neem hierover contact met ons op.
                            </span>
                        </Col>
                    </Row>
                    }

                    {props.options.map((option, index) => {
                        const image = (option.imageUrl ? images(option.imageUrl) : false);
                        return (
                            <Row key={index} className="option-row">
                                <React.Fragment>
                                    <Col xs={12} md={3} className="option-row-image">
                                        {image &&
                                        <div className="option-image">
                                            <img src={image} alt={option.name} width="170" />
                                        </div>
                                        }
                                    </Col>
                                    <Col xs={12} md={8} className="option-row-description">
                                        <h5 className="option-name">
                                            {option.name}
                                        </h5>
                                        <span className="option-descr">{option.descr}</span>
                                    </Col>
                                </React.Fragment>
                            </Row>
                        )
                    })}
                </Container>

            </Modal.Body>
        </Modal>
    );
};

export default Popup;
