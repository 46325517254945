import React from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import history from './history';
import {AuthProvider} from './providers/auth.provider';

import Menu from './components/menu/menu';
import Homepage from './pages/homepage/homepage';

import * as ROUTES from './constants/routes';


class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      position: 0
    };
  }

  componentDidMount() {
    document.title = 'Amaranthos Amaryllis, simply beautiful.';
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  listenToScroll = () => {
    const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

    const height = (document.documentElement.scrollHeight -
        document.documentElement.clientHeight);

    const scrolled = winScroll / height;

    this.setState({
      position: scrolled,
    });
  };

  render() {
    return (
        <AuthProvider>
          <Router history={history}>
            <Menu position={this.state.position} />

            <div className="page-content">
              <Switch>
                <Route path={ROUTES.HOME} exact component={Homepage} />
              </Switch>
            </div>
          </Router>
        </AuthProvider>
    );
  }
}

export default App;


