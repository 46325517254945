
export const HOME = '';

export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';

export const VACANCIES = '/vacatures';
export const VACANCY = '/vacature/:id/:enum';

export const CONTACT = '/contact';
