
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// const firebaseProdConfig = {
//     apiKey: process.env.REACT_APP_PROD_API_KEY,
//     authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
//     projectId: process.env.REACT_APP_PROD_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
// };

const firebaseDevConfig = {
    apiKey: "AIzaSyCZ0zjCnR1aiiilCVlOjSrZvYutHH8H4x0",
    authDomain: "genesis-ff6ed.firebaseapp.com",
    databaseURL: "https://genesis-ff6ed.firebaseio.com",
    projectId: "genesis-ff6ed",
    storageBucket: "genesis-ff6ed.appspot.com",
    messagingSenderId: "1095885069272",
    appId: "1:1095885069272:web:d40cc5a4f97e2554"
};

// const firebaseConfig =
//     process.env.NODE_ENV === 'production' ? firebaseProdConfig : firebaseDevConfig;


firebase.initializeApp(firebaseDevConfig);
firebase.firestore();


export default firebase;
